
.smallcard {
  background: #fff;
  width: 100%;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px 20px;
  &:hover {
    cursor: pointer;
  }
}
