
.level {
  margin-bottom: 0;
}

.card {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  &.user {
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    &.selected {
      .content {
        left: -160px;
      }

      .buttons {
        left: calc(100% - 160px);
      }
    }

    .button {
      width: 30px !important;
      height: 30px !important;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .content {
      transition: left 0.3s;
      padding: 20px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &.gray {
    background: #fff;
  }

  &.card-dotted {
    max-height: 88px;
  }
}
