
.title {
  &.is-6 {
    margin: 5px 0;
    text-transform: capitalize;
  }
}
.card {
  margin-bottom: 10px;
  padding: 15px;
  border-radius: 10px;
  box-shadow: none;
  border: 2px solid #e2e9e9;
  border-bottom-width: 4px;
  display: flex;
  flex-direction: column;
  &.user {
    padding: 0;
    height: 80px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    &.selected {
      .content {
        left: -160px;
      }
      .buttons {
        left: calc(100% - 160px);
      }
    }
    .button {
      width: 30px !important;
      height: 30px !important;
      border-radius: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .content {
      transition: left 0.3s;
      padding: 10px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .buttons {
      border-left: 2px solid #e2e9e9;
      box-sizing: content-box;
      transition: left 0.3s;
      position: absolute;
      top: 0;
      left: 100%;
      height: 100%;
      .card-option-button {
        box-sizing: content-box;
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
        .icon {
          transition: transform 0.2s ease;
        }
        &:hover {
          color: var(--color-hover) !important;
          background-color: var(--background-hover) !important;
          .icon {
            transform: scale(1.3, 1.3);
          }
        }
        p {
          font-size: 10px;
        }
      }
    }
  }
  &.gray {
    background: #fff;
  }
  &.checked {
    border: 3px solid #209cee;
  }
  .list-controls {
    padding-top: 10px;
  }
}
.card-dotted {
  background: #fff;
  border: 3px dashed #e2e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    color: #e2e9e9;
  }
  &:hover {
    border: 3px dashed #209cee;
    color: #209cee;
  }
}

.tag.is-primary {
  color: #000;
  color: #fff;
}
